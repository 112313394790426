import React from 'react'
import { ContentBox, RedHatDisplay } from '../../../components/Styled'
import { FormattedMessage } from 'react-intl'
import './Bottom.scss'

const Bottom = () => {
  return (
    <ContentBox
    varmaxwidth= '100%'
    varwidth= '100%'
    className='backg'
    vardisplay= 'flex'
    varminheight= '404px'
    smminheight = '310px'
    >
        <ContentBox
        varmaxwidth= '1163px'
        varwidth= '90%'
        >
            <RedHatDisplay
            varfontsize = '52px'
            varcolor = '#fff'
            varweight = '600'
            varlineheight = '1.3em'
            varfontstyle = 'italic'
            varfontsizemobile ='24px'
            >
                <FormattedMessage 
                 id='downloads.bottom.frase'
                 values={{
                  br: <br />,
                }} />
            </RedHatDisplay>
        </ContentBox>
    </ContentBox>
  )
}

export default Bottom
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from "../../components/Styled.module.scss";
import stylesGarantia from "../Garantia/Garantia.module.scss"

const Garantia = () => {

  return (
    <div className={stylesGarantia.garantiaSection}>
      <div className={stylesGarantia.container}>
        <div className={stylesGarantia.comillaLeft}>
          <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.29041 0C2.37494 1.86452 1.69486 3.73775 1.25892 5.61098C0.822983 7.47549 0.605015 9.3487 0.605015 11.2219C0.605015 15.7264 1.85184 20.0827 4.35412 24.2997C6.8564 28.5166 10.5444 32.3764 15.4356 35.8876L20.3007 32.7946C20.6494 32.6029 20.911 32.3241 21.1028 31.9669C21.2946 31.6096 21.3905 31.2263 21.3905 30.8516C21.3905 30.3201 21.2597 29.8584 20.9895 29.475C20.7192 29.0917 20.3007 28.5776 19.7253 27.9329C19.3852 27.5147 18.9318 26.9309 18.3826 26.1904C17.8333 25.4411 17.3014 24.5611 16.8045 23.5243C16.3075 22.4962 15.8803 21.3287 15.5141 20.0305C15.1479 18.7323 14.9648 17.3382 14.9648 15.8571C14.9648 14.6722 15.113 13.4437 15.392 12.1629C15.6797 10.8821 16.1418 9.54039 16.7957 8.12893C16.9527 7.82399 17.0224 7.45805 17.0224 7.03984C17.0224 6.35154 16.7522 5.72422 16.2203 5.14918C15.6885 4.57414 14.9561 4.13852 14.0494 3.83358L3.29041 0ZM22.5327 0C21.6172 1.86452 20.9371 3.73775 20.5012 5.61098C20.0653 7.47549 19.8473 9.3487 19.8473 11.2219C19.8473 15.7264 21.0941 20.0827 23.5963 24.2997C26.0986 28.5166 29.7867 32.3764 34.6779 35.8876L39.543 32.7946C39.8917 32.6029 40.1533 32.3241 40.3451 31.9669C40.5369 31.6096 40.6328 31.2263 40.6328 30.8516C40.6328 30.3201 40.502 29.8584 40.2317 29.475C39.9614 29.0917 39.5429 28.5776 38.9675 27.9329C38.6275 27.5147 38.1741 26.9309 37.6248 26.1904C37.0756 25.4411 36.5437 24.5611 36.0467 23.5243C35.5498 22.4962 35.1226 21.3287 34.7564 20.0305C34.3902 18.7323 34.2071 17.3382 34.2071 15.8571C34.2071 14.6722 34.3553 13.4437 34.6343 12.1629C34.922 10.8821 35.3841 9.54039 36.038 8.12893C36.195 7.82399 36.2647 7.45805 36.2647 7.03984C36.2647 6.35154 35.9944 5.72422 35.4626 5.14918C34.9308 4.57414 34.1984 4.13852 33.2916 3.83358L22.5327 0Z" fill="white"/>
          </svg>
        </div>
        <div>
          <p className={styles.garantiaSans}>
            <FormattedMessage
              id="garantia.content"
            />
          </p>
          <p className={styles.autorRoboto}>
            - Ricardo Lopez Rayero
          </p>
        </div>
        
        <div className={stylesGarantia.comillaRight}>
          <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.0143 35.8877C38.9298 34.0232 39.6098 32.15 40.0458 30.2767C40.4817 28.4122 40.6997 26.539 40.6997 24.6658C40.6997 20.1613 39.4529 15.805 36.9506 11.588C34.4483 7.37107 30.7603 3.51133 25.8691 0.000118843L21.004 3.09314C20.6553 3.28482 20.3937 3.56361 20.2019 3.92083C20.0101 4.27805 19.9142 4.66143 19.9142 5.03608C19.9142 5.56755 20.045 6.02931 20.3152 6.41267C20.5855 6.79603 21.004 7.31007 21.5794 7.95481C21.9194 8.37302 22.3729 8.95677 22.9221 9.69735C23.4714 10.4466 24.0033 11.3266 24.5002 12.3634C24.9972 13.3915 25.4244 14.559 25.7906 15.8572C26.1568 17.1554 26.3399 18.5495 26.3399 20.0306C26.3399 21.2155 26.1917 22.444 25.9127 23.7248C25.6249 25.0056 25.1629 26.3473 24.509 27.7588C24.352 28.0637 24.2823 28.4296 24.2823 28.8479C24.2823 29.5362 24.5525 30.1635 25.0844 30.7385C25.6162 31.3136 26.3486 31.7492 27.2553 32.0541L38.0143 35.8877ZM18.772 35.8877C19.6875 34.0232 20.3675 32.15 20.8035 30.2767C21.2394 28.4122 21.4574 26.539 21.4574 24.6658C21.4574 20.1613 20.2106 15.805 17.7083 11.588C15.2061 7.37107 11.518 3.51133 6.6268 0.000115481L1.76172 3.09314C1.41297 3.28481 1.15143 3.56361 0.959614 3.92083C0.767802 4.27805 0.67188 4.66143 0.67188 5.03608C0.67188 5.56755 0.802681 6.02931 1.07296 6.41267C1.34324 6.79603 1.76175 7.31007 2.33719 7.95481C2.67722 8.37301 3.13058 8.95677 3.67986 9.69734C4.22914 10.4466 4.76098 11.3266 5.25795 12.3634C5.75492 13.3915 6.18212 14.559 6.54831 15.8572C6.91449 17.1554 7.09759 18.5495 7.09759 20.0306C7.09759 21.2155 6.94938 22.444 6.67038 23.7248C6.38266 25.0056 5.92058 26.3473 5.26667 27.7588C5.10974 28.0637 5.03998 28.4296 5.03998 28.8479C5.03998 29.5362 5.31024 30.1635 5.84209 30.7385C6.37393 31.3136 7.1063 31.7492 8.01305 32.0541L18.772 35.8877Z" fill="white"/>
          </svg>
        </div>

      </div>

    </div>
  );
};

export default Garantia;